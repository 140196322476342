.border-bottom {
  border-bottom: 1px solid #cfcfcf;
  display: block;
}

.bg-container-accounting {
  background-color: #fff;
  padding: 48px 0px;
}

.mg-top-btn-add-doc-contabil {
  margin-top: 16px;
}

.input-description-doc {
  display: block;
  width: 100%;
  padding: 12px 0px;
  border: 0px;
  border-bottom: 1px solid #ccc;
  color: #555555;
}

.input-description-doc:focus {
  border-bottom: 1px solid rgb(16, 6, 144);
}

.input-description-style {
  font-size: 16px;

  label {
    font-size: 16px;
  }
}

.table-list-docs {
  .MuiTableCell-head {
    font-size: 14px;
    text-transform: uppercase;
  }

  .MuiTableCell-body {
    font-size: 14px;
  }

  .mg-left-10 {
    margin-left: 10px;
  }
}

.MuiButton-label {
  font-size: 12px;
}

.title-confirm-deleting {
  h2 {
    font-size: 18px;
  }
}

.text-confirm-deleting {
  font-size: 16px;
}

.MuiDialogActions-root {
  button {
    font-size: 16px;
  }
}

#panel1a-header {
  padding: 0px;
}

.MuiPickersYearSelection-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;

  .MuiTypography-subtitle1 {
    font-size: 18px;
  }

  .MuiPickersYear-yearSelected {
    margin: 0px;
    font-size: 20px;
  }
}

.MuiPickersMonthSelection-container {
  .MuiPickersMonth-root {
    font-size: 18px;
  }

  .MuiPickersMonth-monthSelected {
    font-size: 20px;
  }

  .MuiPickersMonth-monthDisabled {
    font-size: 18px;
  }
}

.i-ar-title {
  @include font-size(16);
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.i-ar-cards {
  margin-left: -10px;
  margin-right: -10px;
  @include flexbox();
}

.i-ar-card {
  margin: 0 10px;
  @include flex(1 1 calc(100% / 5));
}

.i-ar-card-title {
  color: $color-emb;
  @include font-size(16);
  font-weight: 700;
}

.i-ar-card-value {
  @include flexbox();
  @include align-items(center);
}

.i-ar-card-ico {
  @include font-size(50);
}

.i-ar-value {
  color: #666666;
  @include font-size(18);
  font-weight: 700;
  position: relative;
}

@media (max-width: 930px) {
  .i-ar-cards {
    display: block;
    //@include flex-wrap(wrap);
  }

  .i-ar-card {
    margin: 15px 10px;
    //@include flex(1 1 calc(100% / 5));
  }



// @media (max-width: 930px) {
//   .i-ar-cards {
//     @include flex-wrap(wrap);
//   }

//   .i-ar-card:last-child {
//     margin-bottom: 10px;
//     @include order(-1);
//     @include flex-basis(100%);
//     background-color: transparent;
//     box-shadow: none;

//     .i-ar-card-title {
//       color: #666666;
//       text-align: right;
//     }

//     .i-ar-card-value {
//       @include align-items(flex-end);
//     }

//     .i-ar-value {
//       @include font-size(14);
//     }
//  }

  .i-ar-card-title {
    text-align: center;
  }

  .i-ar-card-value {
    @include flex-direction(column);
  }

  .i-ar-value {
    padding-top: 10px;
  }
}

.swal2-title {
  font-size: 22px !important;
}

.swal2-html-container {
  font-size: 16px;
}

.swal2-styled.swal2-confirm {
  font-size: 14px !important;
}

.swal2-cancel.swal2-styled {
  font-size: 14px !important;
}
