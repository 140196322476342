.zIndex-Popper {
  z-index: 99;
  font-size: 13px;
  left: -40px !important;
}

.zIndex-Popper ul li {
  font-size: 13px;
}
.tooltip-ul {
  border-radius: 3px;
  padding: 0 10px;
  position: relative;
  top: 115%;
  transition: all 250ms;
  z-index: 10;
  display: inline-block;
}
.zIndex-Popper {
  z-index: 99;
  font-size: 13px;
  left: -40px !important;
}

.zIndex-Popper ul li {
  font-size: 13px;
}
.tooltip-ul {
  border-radius: 3px;
  padding: 0 10px;
  position: relative;
  top: 100%;
  transition: all 250ms;
  z-index: 10;
  display: inline-block;
  text-align: right;
}

.i-text-right {
  text-align: right; }

.i-text-center {
  text-align: center; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

ul {
  list-style: none; }

a {
  color: #666666;
  text-decoration: none; }

html {
  font-size: 62.5%; }

html,
body {
  height: 100%; }

body {
  color: #666666;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
  background-color: #fafafa; }

.i-ico-overdue-color {
  color: #A81B1B; }

.i-ico-disput-color {
  color: #F9B32E; }

.i-ico-not-overdue-color {
  color: #100690; }

.i-ico-credit-color {
  color: #009898; }

.i-ico-updated-at-color {
  color: #888; }

.i-main {
  padding-top: 64px; }

.i-container {
  width: 100%;
  max-width: 96.6%;
  margin: 0 auto; }
  @media (max-width: 1244px) {
    .i-container {
      max-width: 99%; } }

.i-profile {
  display: flex; }
  .i-profile .profile-label-info {
    margin-left: 5px; }

.i-title-page {
  color: #100690;
  padding: 30px 0 20px; }

.i-link {
  color: #009898;
  display: inline-block; }
  .i-link .i-link-label {
    vertical-align: super;
    display: inline-block; }
  .i-link .i-link-ico {
    font-size: 22px;
    font-size: 2.2rem; }

.i-link-text-uppercase {
  text-transform: uppercase; }

.i-profile {
  font-size: 12px;
  font-size: 1.2rem;
  padding-left: 35px;
  position: relative; }
  .i-profile .i-profile-ico {
    fill: #100690;
    font-size: 30px;
    font-size: 3rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .i-profile .i-profile-label {
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 700;
    padding-bottom: 2px; }

.i-logo-flyembraer {
  width: 160px;
  height: 32px;
  text-indent: -9999px;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  background-size: cover;
  margin-left: 16px; }

.sep-menu-body {
  display: grid;
  grid-template-columns: 0.2fr 1.8fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-template-areas: "menu body"; }
  @media (min-width: 0px) and (max-width: 1064px) {
    .sep-menu-body {
      grid-template-columns: 2fr;
      grid-template-areas: "body"; } }

.sep-body {
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-template-areas: "body"; }
  .sep-body .o-menu {
    display: none; }
  @media (min-width: 0px) and (max-width: 1064px) {
    .sep-body {
      grid-template-columns: 2fr;
      grid-template-areas: "body"; } }

.o-menu {
  grid-area: menu; }

.o-body {
  grid-area: body; }

.border-bottom {
  border-bottom: 1px solid #cfcfcf;
  display: block; }

.bg-container-accounting {
  background-color: #fff;
  padding: 48px 0px; }

.mg-top-btn-add-doc-contabil {
  margin-top: 16px; }

.input-description-doc {
  display: block;
  width: 100%;
  padding: 12px 0px;
  border: 0px;
  border-bottom: 1px solid #ccc;
  color: #555555; }

.input-description-doc:focus {
  border-bottom: 1px solid #100690; }

.input-description-style {
  font-size: 16px; }
  .input-description-style label {
    font-size: 16px; }

.table-list-docs .MuiTableCell-head {
  font-size: 14px;
  text-transform: uppercase; }

.table-list-docs .MuiTableCell-body {
  font-size: 14px; }

.table-list-docs .mg-left-10 {
  margin-left: 10px; }

.MuiButton-label {
  font-size: 12px; }

.title-confirm-deleting h2 {
  font-size: 18px; }

.text-confirm-deleting {
  font-size: 16px; }

.MuiDialogActions-root button {
  font-size: 16px; }

#panel1a-header {
  padding: 0px; }

.MuiPickersYearSelection-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%; }
  .MuiPickersYearSelection-container .MuiTypography-subtitle1 {
    font-size: 18px; }
  .MuiPickersYearSelection-container .MuiPickersYear-yearSelected {
    margin: 0px;
    font-size: 20px; }

.MuiPickersMonthSelection-container .MuiPickersMonth-root {
  font-size: 18px; }

.MuiPickersMonthSelection-container .MuiPickersMonth-monthSelected {
  font-size: 20px; }

.MuiPickersMonthSelection-container .MuiPickersMonth-monthDisabled {
  font-size: 18px; }

.i-ar-title {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 20px; }

.i-ar-cards {
  margin-left: -10px;
  margin-right: -10px;
  display: flex; }

.i-ar-card {
  margin: 0 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% / 5); }

.i-ar-card-title {
  color: #100690;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 700; }

.i-ar-card-value {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.i-ar-card-ico {
  font-size: 50px;
  font-size: 5rem; }

.i-ar-value {
  color: #666666;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 700;
  position: relative; }

@media (max-width: 930px) {
  .i-ar-cards {
    display: block; }
  .i-ar-card {
    margin: 15px 10px; }
  .i-ar-card-title {
    text-align: center; }
  .i-ar-card-value {
    -moz-flex-direction: column;
    flex-direction: column; }
  .i-ar-value {
    padding-top: 10px; } }

.swal2-title {
  font-size: 22px !important; }

.swal2-html-container {
  font-size: 16px; }

.swal2-styled.swal2-confirm {
  font-size: 14px !important; }

.swal2-cancel.swal2-styled {
  font-size: 14px !important; }

