* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  color: #666666;
  text-decoration: none;
}

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}

body {
  color: #666666;
  font-family: 'Roboto', sans-serif;
  @include font-size(14);
  line-height: 1;
  background-color: #fafafa;
}

.i-ico-overdue-color {
  color: #A81B1B;
}

.i-ico-disput-color {
  color: #F9B32E;
}

.i-ico-not-overdue-color {
  color: $color-emb;
}

.i-ico-credit-color {
  color: $color-tech;
}

.i-ico-updated-at-color {
  color: #888;
}

.i-main {
  padding-top: 64px;
}

.i-container {
  width: 100%;
  max-width: 96.6%;
  margin: 0 auto;

  @media (max-width: 1244px) {
    max-width: 99%;
  }
}

.i-profile {
  display: flex;

  .profile-label-info {
    margin-left: 5px;
  }
}


// .i-section {
//   padding: 30px 24px;

//   @media (max-width: 650px) {
//     padding: 30px 10px;
//   }
// }

.i-title-page {
  color: $color-emb;
  padding: 30px 0 20px;
}

.i-link {
  color: $color-tech;
  display: inline-block;

  .i-link-label {
    vertical-align: super;
    display: inline-block;
  }

  .i-link-ico {
    @include font-size(22);
  }
}

.i-link-text-uppercase {
  text-transform: uppercase;
}

.i-profile {
  @include font-size(12);
  padding-left: 35px;
  position: relative;

  .i-profile-ico {
    fill: $color-emb;
    @include font-size(30);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .i-profile-label {
    @include font-size(14);
    font-weight: 700;
    padding-bottom: 2px;
  }
}

.i-logo-flyembraer {
  width: 160px;
  height: 32px;
  text-indent: -9999px;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  background-size: cover;
  margin-left: 16px;
}

.sep-menu-body {
  display: grid;
  grid-template-columns: 0.2fr 1.8fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "menu body";

  @media (min-width: 0px) and (max-width: 1064px) {
    grid-template-columns: 2fr;
    grid-template-areas:
      "body";
  }
}

.sep-body {
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "body";

    .o-menu {
      display: none;
    }

  @media (min-width: 0px) and (max-width: 1064px) {
    grid-template-columns: 2fr;
    grid-template-areas:
      "body";
  }
}

.o-menu {
  grid-area: menu;
}

.o-body {
  grid-area: body;
}