.tooltip-ul {
  border-radius: 3px;
  padding: 0 10px;
  position: relative;
  top: 100%;
  transition: all 250ms;
  z-index: 10;
  display: inline-block;
  text-align: right;
}
